<template>
  <v-card
    class="pa-4"
    color="white"
  >
    <v-row>
      <v-col>
        <StaffInformation
          :person="staff"
          :is-updating="loading"
        />
      </v-col>
    </v-row>
    <v-row v-if="showUpdateError">
      <v-col cols="12">
        <v-alert
          type="error"
          class="mb-0"
          dismissible
          v-model="showUpdateError"
        >
          {{ $t('couldNotUpdateStaff') }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-0">
        <v-btn
          block
          :loading="loading"
          :disabled="loading"
          color="primary"
          x-large
          @click="updateStaff"
        >
          {{ $t('updateInformation') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import StaffInformation from "../person/StaffInformation"
  export default {
    name: "EditStaff",
    components: {StaffInformation},
    props: {
      staff: {
        type: Object,
        required: true
      }
    },
    data:() => {
      return {
        loading: false,
        showUpdateError: false
      }
    },
    methods: {
      updateStaff() {
        const staff = this.staff
        this.loading = true
        this.$store.dispatch('updateStaff', staff).then(result => {
          if(result) {
            this.$emit('staffUpdated')
            this.$store.commit('updateActionSuccess', {
              message: this.$t('success'),
              subMessage: this.$t('staffInformationWasUpdated')
            })
          } else {
            this.showUpdateError = true
          }
        }).finally(() => {
          this.loading = false
        })
      }
    }
  }
</script>

<style scoped>

</style>
