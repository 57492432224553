<template>
  <v-dialog
    max-width="800"
    :value="true"
    @click:outside="close"
  >
    <v-card>
      <v-card-title>{{ $t('sendInviteToReviewWork') }}</v-card-title>
      <v-card-subtitle>{{ $t('thisInviteWillBeSentTo') }} {{ staffName }}</v-card-subtitle>
      <v-card-text>
        <v-textarea
          :label="$t('optionalMessage')"
          filled
          hide-details
          :placeholder="$t('textHereWillBeShownInTheEmailBody')"
          v-model="optionalMessage"
        />
      </v-card-text>
      <v-card-actions>
        <v-row class="justify-md-space-between">
          <v-col
            cols="12"
            md="auto"
          >
            <v-btn
              large
              text
              color="error"
              @click="close"
              block
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="auto"
          >
            <v-btn
              large
              color="primary"
              @click="confirm"
              block
            >
              {{ $t('sendInvite') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
	name: "StaffInviteReviewWorkDialog",
	props: {
		staff: {
			type: Object,
			default: () => {
			}
		},
		value: {
			type: Boolean
		}
	},
	data() {
		return {
			optionalMessage: null
		}
	},
	methods: {
		close() {
			this.$emit('closed')
		},
		confirm() {
			this.$emit('confirmed', {optionalMessage: this.optionalMessage})
		}
	},
	computed: {
		staffName() {
			return this.staff && this.staff.address && this.staff.address.name_line
		}
	}
}
</script>

<style scoped>

</style>
