<template>
  <v-container>
    <v-row style="display: none;">
      <v-col cols="6">
        <v-date-picker label="start_date" />
      </v-col>
      <v-col cols="6">
        <v-date-picker />
      </v-col>
    </v-row>
    <v-row
      dense
      align="center"
      class="mx-5 pt-3"
    >
      <v-col cols="12">
        {{ $t('selectATimePeriodToDisplayInSummary') }}
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-menu
          ref="dateFromMenu"
          v-model="dateFromMenu"
          :close-on-content-click="false"
          :return-value.sync="selectedDateFrom"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              :placeholder="$t('dateFrom')"
              v-model="selectedDateFrom"
              :label="$t('from')"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              full-width
              persistent-hint
              :hint="$t('dateFormatYYYYMMDDHint')"
            />
          </template>
          <v-date-picker
            v-model="selectedDateFrom"
            v-if="dateFromMenu"
            type="date"
            @click:date="$refs.dateFromMenu.save(selectedDateFrom)"
          />
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-menu
          ref="dateToMenu"
          v-model="dateToMenu"
          :close-on-content-click="false"
          :return-value.sync="selectedDateTo"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              :placeholder="$t('dateTo')"
              v-model="selectedDateTo"
              :label="$t('to')"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              full-width
              persistent-hint
              :hint="$t('dateFormatYYYYMMDDHint')"
            />
          </template>
          <v-date-picker
            v-model="selectedDateTo"
            v-if="dateToMenu"
            type="date"
            @click:date="$refs.dateToMenu.save(selectedDateTo)"
          />
        </v-menu>
      </v-col>
      <v-col v-if="!months || months.length < 1">
        <v-card
          color="grey lighten-2"
          flat
        >
          <v-card-title class="body-1">
            {{ $t('noWork') }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <template v-for="month in months">
      <WorkHoursDetailedTable
        class="ma-5"
        :key="month"
        :caption="month"
        :work="workByMonths[month].work"
        :staff="staff"
        @editWork="editWork"
      />
    </template>
    <WorkDialog
      v-model="showAddWork"
      :type="actionType"
      :staff="staff"
      :work="selectedWork"
      @workHoursUpdated="updateWorkHours"
    />
  </v-container>
</template>

<script>
import WorkHoursDetailedTable from "./WorkHoursDetailedTable"
import WorkDialog from "./WorkDialog"

export default {
	name: 'WorkHoursDetailed',
	props: {
		work: {
			type: Array,
			default: () => []
		},
		staff: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		WorkDialog,
		WorkHoursDetailedTable
	},
	data: () => ({
		showAddWork: false,
		actionType: null,
		selectedWork: null,
		dateFromMenu: null,
		selectedDateFrom: null,
		dateToMenu: null,
		selectedDateTo: null,
	}),
	updated() {
		//this.sortWorkByDate()
	},
	mounted() {
		//this.sortWorkByDate()
	},
	methods: {
		addWork() {
			this.showAddWork = !this.showAddWork
			this.actionType = 'Add'
		},
		editWork(work) {
			this.actionType = 'Edit'
			this.selectedWork = work
			this.showAddWork = !this.showAddWork
		},
		updateWorkHours(success) {
			if(success) {
				this.showWorkDialog = false
				this.selectedWork = null

				const check = this.actionType ? this.actionType.toLowerCase() : this.actionType

				let message = ''

				switch (check) {
					case 'edit':
            message = this.$t('successfullyEditedWorkForString', {string: this.staff?.address.name_line})
						break
					case 'add':
            message = this.$t('successfullyAddedWorkForString', {string: this.staff?.address.name_line})
						break
					default:
						message = this.$t('whateverYouDidItWasSuccessfullyExecuted')
						break
				}

        this.$store.commit('updateActionSuccess', {
          message: this.$t('success'),
          subMessage: message
        })
				this.$emit('updatedWorkHours', true)
			}
		},
		sortWorkByDate() {
			this.work = this.work.sort((a,b) => b.start_date - a.start_date)
		}
	},
	computed: {
		months: function() {
			const keys = Object.keys(this.workByMonths)
			return keys
		},
		workByMonths: function() {
			const months = {}

			let startTime = null
			let endTime = null

			if(this.selectedDateFrom) {
				startTime = new Date(this.selectedDateFrom).getTime()
			}

			if(this.selectedDateTo) {
				endTime = new Date(this.selectedDateTo).setHours(23, 59, 59)
			}

			for(let i in this.work) {
				let log = this.work[i]

				if(startTime !== null) {
					if(log.start_date < startTime) {
						continue
					}
				}

				if(endTime !== null) {
					if(log.start_date > endTime) {
						continue
					}
				}

				if(log.status === 'cancelled' || log.status === 'canceled') {
          continue
        }

				let key = this.$moment(log.start_date).format('MMMM YYYY')

				if(startTime || endTime) {
					key = ''

					if(startTime) {
						key += startTime
					}

					key += '|'

					if(endTime) {
						key += endTime
					}

				}

				if(months[key] === undefined) {
					months[key] = {
						work: []
					}
				}

				months[key].work.push(log)
			}

			return months
		}
	}
}
</script>
