<template>
  <v-container fluid>
    <v-row>
      <v-col>
        {{ $t('noTasks') }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
	name: "StaffTasks",
	props: {
		staff: {
			type: Object,
			default: () => {
			}
		}
	}
}
</script>

<style scoped>

</style>
