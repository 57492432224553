<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ staff.address.name_line }}
          <h4 class="subtitle-1">
            {{ staff.ssn }}
          </h4>
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          color="primary"
          class="mr-4"
          @click="showWorkDialog = true"
        >
          {{ $t('addWork') }}
        </v-btn>
        <v-btn
          color="blue"
          outlined
          class="mr-4"
          @click="showEditStaffDialog = true"
        >
          {{ $t('edit') }}
        </v-btn>
        <v-btn
          color="green"
          outlined
          :loading="isUpdatingStaff"
          class="mr-4"
          @click="showSendWorkInviteDialog = true"
        >
          {{ $t('sendHoursToReview') }}
        </v-btn>
        <v-btn
          color="green"
          outlined
          :loading="isUpdatingStaff"
          v-if="!staff.active"
          @click="updateStaffStatus(true)"
        >
          {{ $t('enable') }}
        </v-btn>
        <v-btn
          color="red"
          outlined
          :loading="isUpdatingStaff"
          v-if="staff.active"
          @click="updateStaffStatus(false)"
        >
          {{ $t('disable') }}
        </v-btn>
      </v-toolbar>
      <v-tabs
        dense
        v-model="selectedAppBarTab"
      >
        <v-tab href="#work-list">
          {{ $t('work') }}
        </v-tab>
        <v-tab href="#work-summary">
          {{ $t('summary') }}
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items v-model="selectedAppBarTab">
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
      >
        <WorkList
          v-if="tab.key === 'work-list'"
          :work="work"
          :step="200"
          :staff="staff"
          hide-names
          @updatedWorkHours="updatedWorkHours"
        />
        <WorkHoursDetailed
          v-if="tab.key === 'work-summary'"
          :work="work"
          :staff="staff"
          @updatedWorkHours="updatedWorkHours"
        />
        <StaffTasks
          v-if="tab.key === 'tasks'"
          :staff="staff"
        />
      </v-tab-item>
    </v-tabs-items>
    <WorkDialog
      v-if="showWorkDialog"
      v-model="showWorkDialog"
      type="Add"
      :staff="staff"
      @workHoursUpdated="updatedWorkHours"
    />
    <StaffInviteReviewWorkDialog
      v-model="showSendWorkInviteDialog"
      v-if="showSendWorkInviteDialog"
      :staff="staff"
      @closed="closedSendWorkInviteDialog"
      @confirmed="confirmedSendWork"
    />
    <v-dialog
      v-model="showEditStaffDialog"
      width="500"
    >
      <EditStaff
        v-if="showEditStaffDialog"
        :staff="staff"
        @staffUpdated="showEditStaffDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>

import WorkHoursDetailed from "@/components/WorkHoursDetailed"
import WorkList from "@/components/WorkList"
import StaffTasks from "@/components/person/StaffTasks"
import EditStaff from "./EditStaff"
import StaffInviteReviewWorkDialog from "@/components/person/StaffInviteReviewWorkDialog"
import WorkDialog from "@/components/WorkDialog"

export default {
  name: 'Staff',
  props: {
    staff: {
      type: Object,
      default: () => ({})
    },
  },
	components: {
    WorkDialog,
    EditStaff,
		StaffTasks,
		WorkHoursDetailed,
		WorkList,
    StaffInviteReviewWorkDialog
	},
	data() {
		return {
			work: [],
      selectedAppBarTab: 'work-list',
      showEditStaffDialog: false,
      showSendWorkInviteDialog: false,
      showWorkDialog: false,
      isSendingInvite: false
		}
	},
	computed: {
		isUpdatingStaff() {
			return this.$store.state.isUpdatingStaff
		},
		tabs() {

			const tabs = []

			tabs.push({
				title: this.$t('workList'),
				key: 'work-list'
			})

			tabs.push({
				title: this.$t('workSummary'),
				key: 'work-summary'
			})

			tabs.push({
				title: this.$t('tasks'),
				key: 'tasks'
			})

			return tabs
		}
	},
	watch: {
		staff() {
			this.getWorkHours()
		}
	},
	mounted() {
		this.getWorkHours()
	},
	methods: {
    sendWorkReviewInvite(settings) {
      this.isSendingInvite = true

      if(settings.optionalMessage) {
        settings.optionalMessage = settings.optionalMessage.replace(/$/gm, '<br>').replace(/(\r\n|\r|\n)/gm, '')
      }

      this.$store.dispatch('sendWorkReviewInvite', {
        staff: this.staff,
        optionalMessage: settings.optionalMessage,
      }).then(result => {
        if(result) {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'),
            subMessage: this.$t('emailWasSent')
          })
        } else if(result === false) {
          let href = 'mailto:salescloud@salescloud.is'
          let subject = '?subject=Unable%20to%20send%20work%20review%20invite%20e-mail'
          let body = '&body=Regarding%20staff%20id:%20' + this.staff.uuid + ',%20name:%20' + this.staff.address.name_line
          href = href + subject + body
          let subMessage = this.$t('makeSureStaffHasEmailAndPIN') + '\n ' +
            this.$t('ifEverythingSeemsToBeInOrderPleaseContactUsVia') + ' ' +
            '<a style="color: #f0f0f0; text-decoration: none;" href="' + href + '">salescloud@salescloud.is</a>'
          this.$store.commit('updateActionError', {
            message: this.$t('unableToSendInviteEmail'),
            subMessage: subMessage
          })
        }
      }).catch(() => {
        this.$store.commit('updateActionError', {
          message: this.$t('error'),
          subMessage: this.$t('unableToSendInviteEmail')
        })
      }).finally(() => {
        this.isSendingInvite = false
      })
    },
    confirmedSendWork(settings) {
			this.showSendWorkInviteDialog = !this.showSendWorkInviteDialog
			this.sendWorkReviewInvite(settings)
		},
    closedSendWorkInviteDialog() {
      this.showSendWorkInviteDialog = false
    },
		updateStaffStatus(status) {
			const staff = {
				uuid: this.staff.uuid,
				active: status
			}
			this.$store.dispatch('updateStaffStatus', staff)
		},
		getWorkHours() {
			this.work = null
			this.$store.dispatch('getWorkByStaff', {
			uuid: this.staff.uuid,
			startTimeInMillis: 0,
			endTimeInMillis: Date.now()
		}).then(works => {
			this.work = works
			if(this.work) {
				this.work.sort((a,b) => b.start_date - a.start_date)
			}
		})
		},
		updatedWorkHours(success) {
      this.showWorkDialog = false
			if(success) {
				this.$store.dispatch('getWorkByStaff', {
					uuid: this.staff.uuid,
					startTimeInMillis: 0,
					endTimeInMillis: Date.now()
				}).then(works => {
					this.work = works
					if(this.work) {
						this.work.sort((a,b) => b.start_date - a.start_date)
					}
				})
			}
		}
	}
}

</script>

<style>

table {
  margin-bottom: 0;
}

</style>
